import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { IconButton, Typography } from "@mui/material";
import { RefObject } from "react";
import FadeIn from "react-fade-in";

import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";
import { executeScroll } from "../utils";

export const BeyondYourBody = ({
	nextRef,
}: {
	nextRef: RefObject<HTMLDivElement>;
}) => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"overflow-hidden sticky top-0 screen relative " +
				(isMd ? "flex-vcenter justify-around " : "flex-col-center ") +
				(isSm ? "p-12" : "p-0")
			}
		>
			<FadeIn
				className={
					"flex-col-center backdrop-blur-md bg-gray-400/20 shadow-md w-full h-1/3 " +
					(isSm ? "p-12 rounded-lg" : "p-2")
				}
				transitionDuration={2000}
				delay={200}
			>
				<Typography
					color={"white"}
					variant={isMd ? "h1" : isSm ? "h2" : "h3"}
					fontWeight={800}
					textAlign="center"
					fontFamily={"Lobster Two"}
				>
					Beyond Your Body
				</Typography>
				<Typography
					color="white"
					variant={isMd ? "h5" : "h6"}
					textAlign="center"
				>
					Introducing the latest innovation in the field of women
					wellness
				</Typography>
				<IconButton
					className="text-white transition bounce mt-4"
					onClick={() => executeScroll(nextRef)}
				>
					<ExpandMoreRoundedIcon />
				</IconButton>
			</FadeIn>
			<img
				src={require(`../../../assets/backgrounds/hands-${
					isSm ? "horizontal" : "vertical"
				}-bg.png`)}
				className={"absolute full"}
				style={{
					objectFit: "cover",
					zIndex: -10,
				}}
				alt=""
			/>
		</div>
	);
};
