import { Typography } from "@mui/material";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

export const AIPoweredApplication = () => {
	const isLg = useIsBreakpoint("lg");
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"bg-[#e6bbc4] sticky top-0 screen " +
				(isMd
					? "flex-vcenter justify-around "
					: "flex flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<div style={{ ...(isSm && { maxWidth: 540 }) }}>
				<Typography
					variant="h4"
					fontWeight="bold"
					fontStyle={"italic"}
					className="ezg-red"
					fontFamily={"Lobster Two"}
					gutterBottom
				>
					AI-Powered Application
				</Typography>
				<div className="h-0.5 w-full bg-white -mt-1 mb-2 rounded-full" />
				<Typography
					variant={isSm ? "h5" : "body1"}
					className="ezg-red"
					fontSize={isMd ? 24 : 20}
				>
					This cutting-edge app is built on advanced artificial
					intelligence algorithms that cater to your unique needs and
					preferences by using machine learning process, providing a
					personalized experience like no other.
				</Typography>
			</div>
			<img
				src={require("../../../assets/app-shots/app-shots.png")}
				className="w-full"
				style={{
					width: isLg ? 740 : isMd ? 500 : 400,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
		</div>
	);
};
