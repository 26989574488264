import { Typography } from "@mui/material";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

export const LubricationCapsules = () => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"bg-[#ebc6ce] sticky top-0 screen " +
				(isMd
					? "flex-vcenter justify-around "
					: "flex flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<div style={{ ...(isSm && { maxWidth: 540 }) }}>
				<Typography
					variant="h4"
					fontWeight="bold"
					fontStyle={"italic"}
					className="ezg-red"
					fontFamily={"Lobster Two"}
					gutterBottom
				>
					Lubrication PH Balanced Capsules
				</Typography>
				<div className="h-0.5 w-full bg-white -mt-1 mb-2 rounded-full" />
				<Typography
					variant={isSm ? "h5" : "body1"}
					className="ezg-red"
					fontSize={isMd ? 24 : 18}
				>
					To enhance your experience, we've included CBD capsules
					(subject to legal approvals) or natural ingredient lube for
					added pleasure and comfort. Whether you seek excitement or
					better sexual wellness, our AI vibrator ensures your
					satisfaction.
				</Typography>
			</div>
			<img
				src={require("../../../assets/vibrator/vib-4.png")}
				style={{
					width: isMd ? 500 : isSm ? 300 : 240,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
		</div>
	);
};
