import { Typography } from "@mui/material";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

export const Sense = () => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"bg-[#e6bbc4] sticky top-0 screen " +
				(isMd
					? "flex-vcenter justify-around "
					: "flex flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<div style={{ ...(isSm && { maxWidth: 540 }) }}>
				<Typography
					variant="h4"
					fontWeight="bold"
					fontStyle={"italic"}
					className="ezg-red"
					fontFamily={"Lobster Two"}
					gutterBottom
				>
					Sense
				</Typography>
				<div className="h-0.5 w-full bg-white -mt-1 mb-2 rounded-full" />
				<Typography
					variant={isSm ? "h5" : "body1"}
					className="ezg-red"
					fontSize={isMd ? 24 : 18}
				>
					Our device features a heart rate sensor, pressure sensor,
					and EC sensor that work together to learn about your body
					and your sexual preferences. With this information, our ML -
					AI algorithm creates a tailored experience that will leave
					you feeling satisfied every time.
				</Typography>
			</div>
			<img
				src={require("../../../assets/vibrator/vib-2.png")}
				style={{
					width: isMd ? 500 : isSm ? 300 : 240,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
		</div>
	);
};
