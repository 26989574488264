import { Typography } from "@mui/material";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";
import { forwardRef } from "react";

export const AIBasedDevice = forwardRef<HTMLDivElement>((_, ref) => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			ref={ref}
			className={
				"bg-[#ebc6ce] sticky top-0 screen " +
				(isMd
					? "flex-vcenter justify-around "
					: "flex flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<div style={{ ...(isSm && { maxWidth: 540 }) }}>
				<Typography
					variant="h4"
					fontWeight="bold"
					fontStyle={"italic"}
					className="ezg-red"
					fontFamily={"Lobster Two"}
					gutterBottom
				>
					AI Based Device
				</Typography>
				<div className="h-0.5 w-full bg-white -mt-1 mb-2 rounded-full" />
				<Typography
					variant={isSm ? "h5" : "body1"}
					className="ezg-red"
					fontSize={isMd ? 24 : 20}
				>
					Designed with the modern woman in mind, this device is
					equipped with cutting-edge technology that offers a truly
					unique and personalized experience
				</Typography>
			</div>
			<img
				src={require("../../../assets/vibrator/vib-1.png")}
				style={{
					width: isMd ? 500 : isSm ? 300 : 240,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
		</div>
	);
});
