import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import ModalContainer from "react-modal-promise";

import { LandingPage } from "./pages/landing/LandingPage";
import { theme } from "./theme";

import "./styles/bounce.css";
import "./styles/shake.css";
import "./styles/styles.css";

export const AppLoader = () => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<ModalContainer />
				<LandingPage />
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
