import { Typography } from "@mui/material";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

export const UnboxPleasure = () => {
	const isMd = useIsBreakpoint("md");
	const isLg = useIsBreakpoint("lg");
	return (
		<div className="bg-[#e6bbc4] sticky top-0 flex-col-center screen">
			<div className="w-full h-1/2 flex-col-center">
				<Typography
					variant="h4"
					fontWeight={900}
					fontStyle="italic"
					className="ezg-red"
					fontFamily={"Lobster Two"}
				>
					Unbox Pleasure
				</Typography>
			</div>
			<div
				className={
					"h-1/2 flex-center relative " +
					(isLg ? "w-3/5" : isMd ? "w-5/6" : "w-full")
				}
			>
				<img
					src={require("../../../assets/capsules/capsules-box.png")}
					className="absolute bottom-0 left-0 w-full h-full"
					style={{
						objectFit: "cover",
					}}
					alt=""
				/>
			</div>
		</div>
	);
};
