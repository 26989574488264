import { ButtonBase, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { useIsBreakpoint } from "../hooks/useIsBreakpoint";
import { ReserveDeviceForm } from "./ReserveDeviceForm";

const useReserveDeviceBanner = () => {
	const [wasOpened, setWasOpened] = useState(false);

	const open = useCallback(() => {
		setWasOpened(true);
		ReserveDeviceForm();
	}, []);

	useEffect(() => {
		if (wasOpened) return;
		const timeout = setTimeout(open, 5000);
		return () => {
			clearTimeout(timeout);
		};
	}, [wasOpened, open]);

	return {
		open,
	};
};

export const ReserveDeviceBanner = () => {
	const isMd = useIsBreakpoint("md");

	const { open } = useReserveDeviceBanner();

	return (
		<ButtonBase
			onClick={open}
			style={{ height: "2.56rem" }}
			className={
				"bg-[#f8f7f5] fixed px-3 rounded-t-lg shadow-xl bottom-0 " +
				(isMd ? "right-12" : "right-4")
			}
		>
			<Typography
				fontWeight={800}
				className="text-transparent bg-clip-text bg-gradient-to-r from-[#ff888b] to-[#fac06f]"
			>
				Reserve Device
			</Typography>
		</ButtonBase>
	);
};
