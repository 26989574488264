import { useRef } from "react";

import { ForiaInsideBanner } from "../../components/ForiaInsideBanner";
import { ReserveDeviceBanner } from "../../components/ReserveDeviceBanner";
import { Abilities } from "./parts/Abilities";
import { AIBasedDevice } from "./parts/AIBasedDevice";
import { AIPoweredApplication } from "./parts/AIPoweredApplication";
import { BeyondYourBody } from "./parts/BeyondYourBody";
import { CBDBenefits } from "./parts/CBDBenefits";
import { CBDForWomen } from "./parts/CBDForWomen";
import { ExtraInformation } from "./parts/ExtraInformation";
import { EZG } from "./parts/EZG";
import { LubricationCapsules } from "./parts/LubricationCapsules";
import { Sense } from "./parts/Sense";
import { UnboxPleasure } from "./parts/UnboxPleasure";
import { VideoPreview } from "./parts/VideoPreview";

export const LandingPage = () => {
	const page1Ref = useRef<HTMLDivElement>(null);
	const page2Ref = useRef<HTMLDivElement>(null);

	return (
		<div>
			<BeyondYourBody nextRef={page1Ref} />
			<EZG ref={page1Ref} nextRef={page2Ref} />
			<AIBasedDevice ref={page2Ref} />
			<Sense />
			<Abilities />
			<AIPoweredApplication />
			<LubricationCapsules />
			<UnboxPleasure />
			<CBDForWomen />
			<CBDBenefits />
			<VideoPreview />
			<ExtraInformation />
			<ForiaInsideBanner />
			<ReserveDeviceBanner />
		</div>
	);
};
