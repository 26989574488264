import { RefObject } from "react";

export type LandingPartProps = {
	prevRef: RefObject<HTMLDivElement>;
	nextRef: RefObject<HTMLDivElement>;
};

export const executeScroll = (ref?: React.RefObject<HTMLDivElement>) => {
	ref?.current?.scrollIntoView({ behavior: "smooth" });
};
