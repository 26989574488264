import { ButtonBase, Divider, Modal, Paper, Typography } from "@mui/material";
import { create } from "react-modal-promise";
import { useIsBreakpoint } from "../hooks/useIsBreakpoint";

export const ForiaInfo = create(({ isOpen, onResolve }) => {
	const isMd = useIsBreakpoint("md");

	return (
		<Modal
			open={isOpen}
			onClose={onResolve}
			className="flex-center p-4"
			disableAutoFocus
		>
			<Paper className={"rounded-lg " + (isMd ? "p-8" : "p-6")}>
				<img
					src={require("../assets/logos/foria-inside-white.png")}
					className="w-48 mb-3"
					style={{
						objectFit: "contain",
					}}
					alt=""
				/>
				<Divider className="w-full mb-2" />
				<Typography gutterBottom>
					Our oils capsules are charged with the finest CBD oil
					sourced from the renowned Foria brand, a symbol of
					excellence in wellness.
				</Typography>
				<Typography>
					<a
						href="https://www.foriawellness.com/pages/about-foria"
						style={{ fontWeight: "bold" }}
						rel="noreferrer"
						target="_blank"
					>
						Check out more about Foria
					</a>
				</Typography>
			</Paper>
		</Modal>
	);
});

export const ForiaInsideBanner = () => {
	const isMd = useIsBreakpoint("md");

	return (
		<ButtonBase
			onClick={ForiaInfo}
			className={
				"fixed p-3 rounded-t-lg bg-[#f8f7f5] shadow-xl flex-center bottom-0 " +
				(isMd ? "left-12 " : "left-4")
			}
		>
			<img
				src={require("../assets/logos/foria-inside-gray.png")}
				className="w-32"
				style={{
					objectFit: "contain",
				}}
				alt=""
			/>
		</ButtonBase>
	);
};
