import BalanceRoundedIcon from "@mui/icons-material/BalanceRounded";
import EmojiObjectsRoundedIcon from "@mui/icons-material/EmojiObjectsRounded";
import HealthAndSafetyRoundedIcon from "@mui/icons-material/HealthAndSafetyRounded";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import { Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

const CBDBenefit = ({
	Icon,
	text,
}: {
	Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
	text: string;
}) => {
	const isLg = useIsBreakpoint("lg");
	const isMd = useIsBreakpoint("md");

	return (
		<Grid item xs={6} md={4}>
			<div
				className={
					"flex-col-center space-y-2 full bg-[#ebc6ce] rounded-lg " +
					(isMd ? "p-4" : "p-2")
				}
			>
				<Icon
					className={
						"ezg-red " +
						(isLg ? "w-16 h-16" : isMd ? "w-14 h-14" : "w-12 h-12")
					}
				/>
				<Typography
					align="center"
					className="ezg-red"
					fontSize={isLg ? 24 : isMd ? 20 : 16}
				>
					{text}
				</Typography>
			</div>
		</Grid>
	);
};

export const CBDBenefits = () => {
	const isLg = useIsBreakpoint("lg");
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"bg-[#e6bbc4] sticky top-0 screen flex flex-col-center " +
				(isSm ? "p-12" : "p-6")
			}
		>
			<Typography
				variant="h4"
				fontWeight="bold"
				fontStyle={"italic"}
				className="ezg-red w-full"
				fontFamily={"Lobster Two"}
				align="center"
			>
				Benefits of CBD
			</Typography>
			<a
				href="https://www.mindbodygreen.com/articles/hemp-oil-for-sex"
				style={{ fontWeight: "bold", fontSize: isMd ? 24 : 20 }}
				rel="noreferrer"
				target="_blank"
				className="ezg-red mb-4"
			>
				<Typography variant="subtitle2" textAlign={"center"}>
					Check out How Hemp Oil Makes For Better Sex
				</Typography>
			</a>
			<div
				className="h-0.5 w-full bg-white -mt-1 mb-4 rounded-full"
				style={{ ...(isSm && { maxWidth: 540 }) }}
			/>
			<Grid container className="full" spacing={isLg ? 8 : isMd ? 4 : 2}>
				<CBDBenefit
					text="Reduces levels of cortisol"
					Icon={HealthAndSafetyRoundedIcon}
				/>

				<CBDBenefit
					text="Reduces stress and anxiety"
					Icon={MonitorHeartRoundedIcon}
				/>

				<CBDBenefit
					text="Natural inflammation-zapper reduces chronic pain"
					Icon={WhatshotRoundedIcon}
				/>

				<CBDBenefit
					text="Helps you sleep better by relaxing muscles"
					Icon={HotelRoundedIcon}
				/>

				<CBDBenefit
					text="Balances hormones which can alter your sex drive"
					Icon={BalanceRoundedIcon}
				/>

				<CBDBenefit
					text="Improves brain function especially short-term memory"
					Icon={EmojiObjectsRoundedIcon}
				/>
			</Grid>
		</div>
	);
};
