import { ButtonBase, Checkbox, FormControlLabel, FormGroup, Modal, Paper, TextField, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { create } from "react-modal-promise";
import { useMap } from "react-use";
import validator from "validator";

import { useIsBreakpoint } from "../hooks/useIsBreakpoint";
import { CustomerStatus, Gender, getEzgToken, reserveDevice } from "../services/reserveDeviceService";

export const ReserveDeviceForm = create(({ isOpen, onResolve }) => {
	const isMd = useIsBreakpoint("md");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [phase, setPhase] = useState<"form" | "succeeded">("form");
	const [form, { set }] = useMap<{
		firstName: string;
		lastName: string;
		mail: string;
		agreedToTerms: boolean;
		gender: Gender;
		status: CustomerStatus;
	}>({
		firstName: "",
		lastName: "",
		mail: "",
		agreedToTerms: false,
		gender: Gender.female,
		status: CustomerStatus.earlyRegistration,
	});

	const disabled = useMemo(
		() => loading || !form.agreedToTerms,
		[loading, form.agreedToTerms]
	);

	const reserve = useCallback(async () => {
		if (!form.agreedToTerms) return;
		if (form.firstName.length < 2 || !validator.isAlpha(form.firstName)) {
			setErrorMessage("Please enter a valid first name");
			return;
		}
		if (form.lastName.length < 2 || !validator.isAlpha(form.lastName)) {
			setErrorMessage("Please enter a valid last name");
			return;
		}
		if (!validator.isEmail(form.mail)) {
			setErrorMessage("Please enter a valid email address");
			return;
		}

		setLoading(true);
		const tokenResponse = await getEzgToken();
		if (tokenResponse.errCode === "0") {
			const reserveResponse = await reserveDevice(
				tokenResponse.token,
				form
			);
			if (reserveResponse.errCode === "0") setPhase("succeeded");
			else setErrorMessage(reserveResponse.errMsg);
		} else setErrorMessage(tokenResponse.errMsg);
		setLoading(false);
	}, [form]);

	return (
		<Modal
			open={isOpen}
			onClose={onResolve}
			className="flex-center p-4"
			disableAutoFocus
		>
			<Paper className={"rounded-lg " + (isMd ? "p-8" : "p-6")}>
				{phase === "form" ? (
					<>
						<Typography variant="h4" fontFamily={"Lobster Two"}>
							Our Pleasure
						</Typography>
						<Typography variant="body1">
							Be among the first to reserve a device for yourself
							in the pre-order that will open soon!
						</Typography>
						<div className="h-0.5 w-full bg-gradient-to-r from-[#ff888b] to-[#ffcd88] mt-3 rounded-full" />
						<div className="space-y-4 mt-4">
							<div className="flex justify-between space-x-4">
								<TextField
									label="First name"
									variant="filled"
									fullWidth
									required
									inputProps={{
										autoCapitalize: "words",
									}}
									value={form.firstName}
									onChange={(e) =>
										set("firstName", e.target.value)
									}
								/>
								<TextField
									label="Last name"
									variant="filled"
									fullWidth
									required
									inputProps={{
										autoCapitalize: "words",
									}}
									value={form.lastName}
									onChange={(e) =>
										set("lastName", e.target.value)
									}
								/>
							</div>
							<TextField
								label="Mail"
								variant="filled"
								fullWidth
								required
								type="email"
								onChange={(e) => set("mail", e.target.value)}
							/>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											value={form.agreedToTerms}
											onChange={(e) =>
												set(
													"agreedToTerms",
													e.target.checked
												)
											}
										/>
									}
									label="I agree to receive updates via email and to abide by the Terms of Service and Privacy Policy"
								/>
							</FormGroup>
							{errorMessage && (
								<Typography color="error">
									{errorMessage}
								</Typography>
							)}
							<div className="w-full flex flex-row-reverse">
								<ButtonBase
									disabled={disabled}
									className={
										"shadow-lg shadow-[#ff888b]/50 bg-gradient-to-r from-[#ff888b] to-[#ffcd88] text-white px-5 py-3 rounded-md " +
										(disabled
											? "opacity-30"
											: "opacity-100")
									}
									onClick={reserve}
								>
									{loading
										? "Loading"
										: "Reserve Your Device"}
								</ButtonBase>
							</div>
						</div>
					</>
				) : (
					<div className="flex flex-col-center space-y-4">
						<Typography variant="h4" fontFamily={"Lobster Two"}>
							That's it!
						</Typography>
						<Typography variant="body1" textAlign="center">
							We will be in touch with you soon!
						</Typography>
						<ButtonBase
							className={
								"shadow-lg shadow-[#ff888b]/50 bg-gradient-to-r from-[#ff888b] to-[#ffcd88] text-white px-5 py-3 rounded-md " +
								(disabled ? "opacity-30" : "opacity-100")
							}
							onClick={onResolve}
						>
							Close
						</ButtonBase>
					</div>
				)}
			</Paper>
		</Modal>
	);
});
