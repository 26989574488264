import { ButtonBase, Typography } from "@mui/material";
import { RefObject, forwardRef } from "react";
import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";
import { executeScroll } from "../utils";
import FadeIn from "react-fade-in";

export const EZG = forwardRef<
	HTMLDivElement,
	{ nextRef: RefObject<HTMLDivElement> }
>(({ nextRef }, ref) => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			ref={ref}
			className={
				"overflow-hidden bg-white sticky top-0 screen relative " +
				(isMd ? "flex-vcenter justify-around " : "flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<FadeIn
				className="flex-col-center "
				transitionDuration={2000}
				delay={200}
			>
				<Typography
					variant={isMd ? "h1" : isSm ? "h2" : "h3"}
					fontWeight={700}
					fontFamily={"Lobster Two"}
				>
					EZG
				</Typography>
				<Typography
					color="textSecondary"
					variant={isMd ? "h5" : "h6"}
					textAlign="center"
				>
					CBD Oil Infused Pleasure
					<br />
					AI-Controlled
				</Typography>
				<ButtonBase
					className="shadow-lg shadow-[#ff888b]/50 bg-gradient-to-r from-[#ff888b] to-[#ffcd88] text-white px-5 py-3 rounded-md mt-4"
					onClick={() => executeScroll(nextRef)}
				>
					<Typography variant="h6" fontWeight={"bold"}>
						Take a Tour
					</Typography>
				</ButtonBase>
			</FadeIn>

			<img
				src={require("../../../assets/vibrator/vib-0.png")}
				className="shake"
				style={{
					width: isMd ? 400 : isSm ? 300 : 200,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
			<img
				src={require(`../../../assets/backgrounds/ai-bg-${
					isMd ? "right" : "bottom"
				}.png`)}
				className={
					"absolute shake-slower " +
					(isMd
						? "w-96 h-full right-0 top-0 "
						: "w-full left-0 bottom-0 ") +
					(isSm ? "" : "h-80")
				}
				style={{
					objectFit: "cover",
					zIndex: -10,
					opacity: 0.6,
				}}
				alt=""
			/>
		</div>
	);
});
