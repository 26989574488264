import { createTheme } from "@mui/material/styles";

const font = "'Poppins', sans-serif";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#ff888b",
		},
		secondary: {
			main: "#ebc6ce",
		},
	},
	typography: {
		fontFamily: font,
		button: { textTransform: "none" },
	},
	components: {
		MuiTypography: {
			defaultProps: {
				lineHeight: 1.5,
			},
		},
	},
});
