import axios from "axios";

export const getEzgToken = async () => {
	const url =
		"https://webapi.beyond-crm.com/api/User/loginEZGWeb?user=System&password=System1234";
	const response = await axios.get<{
		data: {
			errCode: string;
			errMsg: string;
			token: string;
		}[];
	}>(url);
	return response.data.data[0];
};

export enum Gender {
	female = 1,
	male = 2,
}

export enum CustomerStatus {
	earlyRegistration = 1,
}

export const reserveDevice = async (
	token: string,
	registrationData: {
		agreedToTerms: boolean;
		gender: Gender;
		firstName: string;
		lastName: string;
		mail: string;
		phonePrefix?: string;
		phoneNumber?: string;
		status?: CustomerStatus;
	}
) => {
	const url = "https://webapi.beyond-crm.com/api/Customer/saveEZGCustomer";
	const { agreedToTerms, ...body } = registrationData;
	const response = await axios.post<{
		data: {
			errCode: string;
			errMsg: string;
			customerID: string;
		}[];
	}>(url, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return response.data.data[0];
};
