import React from "react";

export const VideoPreview = () => {
	return (
		<div className={"bg-[#d4d4d2] flex-center sticky top-0 screen"}>
			<video
				className="screen object-cover"
				src={require("../../../assets/video/ezg.mp4")}
				autoPlay
				playsInline
				loop
				muted
			/>
		</div>
	);
};
