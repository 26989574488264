import { Typography } from "@mui/material";

import { useIsBreakpoint } from "../../../hooks/useIsBreakpoint";

export const CBDForWomen = () => {
	const isMd = useIsBreakpoint("md");
	const isSm = useIsBreakpoint("sm");

	return (
		<div
			className={
				"bg-[#ebc6ce] sticky top-0 screen " +
				(isMd
					? "flex-vcenter justify-around "
					: "flex flex-col-center ") +
				(isSm ? "p-12" : "p-6")
			}
		>
			<div style={{ ...(isSm && { maxWidth: 540 }) }}>
				<Typography
					variant="h4"
					fontWeight="bold"
					fontStyle={"italic"}
					className="ezg-red"
					fontFamily={"Lobster Two"}
					gutterBottom
				>
					CBD For Women
				</Typography>
				<div className="h-0.5 w-full bg-white -mt-1 mb-2 rounded-full" />
				<Typography
					variant={isSm ? "h5" : "body1"}
					className="ezg-red"
					fontSize={isMd ? 24 : 20}
					gutterBottom
				>
					According to studies<sup>1</sup>, CBD oil offers a range of
					benefits for women, including reducing vaginal discomfort,
					increasing lubrication, heightening arousal, providing
					relief for painful sex, and easing stress and anxiety.
				</Typography>
				<a
					href="https://www.mindbodygreen.com/articles/hemp-oil-for-sex"
					style={{ fontWeight: "bold", fontSize: isMd ? 24 : 20 }}
					rel="noreferrer"
					target="_blank"
					className="ezg-red"
				>
					<Typography
						variant="subtitle2"
						style={{ fontWeight: "bold" }}
					>
						1 - How Hemp Oil Makes For Better Sex
					</Typography>
				</a>
			</div>
			<img
				src={require("../../../assets/capsules/pink-capsules.png")}
				style={{
					width: isMd ? 500 : isSm ? 300 : 400,
					objectFit: "contain",
					...(!isMd && { flexGrow: 1 }),
				}}
				alt=""
			/>
		</div>
	);
};
